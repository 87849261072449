.navBody {
    background-color: white;
    height: 100px;
    font-size: 100px;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    animation: fadeIn 1s ease-in forwards;
}

.logo {
    background-image: url('../../onlyLogo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: .85em;
    margin: .05em 0 .05em 20px;
    width: .5em;
    display: flex;
    align-self: center;
    /* opacity: 0;
    animation: fadeIn .25s ease-in forwards;
    animation-delay: 2s; */
}

.buttonContainer {
    height: .7em;
    display: flex;
    align-self: center;
    margin: 0 20px 0 0;
}

.contactUs {
    cursor: pointer;
    font-family: 'Playfair Display SC', serif;
    font-size: 16px;
    display: flex;
    border: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
    background-color: black;
    color: rgb(225, 225, 225);
    text-decoration: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0 10px;
    width: 120px;
}

.contactUs:hover {
    background-color: white;
    color: black;
}

.clickTrap {
    height: 100vh;
    width: 100%;
    background: transparent;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 4;
}

.bigName {
    font-size: 40px;
    font-family: 'Playfair Display SC', serif;
    align-self: center;
    cursor: default;
}

@media only screen and (max-width: 505px) {
    .bigName {
        display: none;
    }
    .logo {
        background-image: url('../../logo.svg');
    }
}

/* .container:hover .path {
    animation: dash 13s linear forwards;
} */
  
 