@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.content {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
}

.section {
    background: transparent;
    position: relative; 
    overflow: hidden;
}

.background {
    background-image: url('./bg-mountains.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
}

.headerContainer {
    width: 99%;
    margin: 30px 0 0 1%;
}

.headerText {
    font-size: 64px;
    margin: auto;
    font-family: 'Playfair Display SC', serif;
    cursor: default;

}
@media only screen and (min-width: 1919px) {
    .headerText {
        font-size: 104px;
    }
}
@media only screen and (min-width: 2559px) {
    .headerText {
        font-size: 154px;
    }
}
@media only screen and (max-width: 505px) {
    .headerText {
        font-size: 40px;
    }
    .background {
        height: 160vh;
        width: 100vw;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
    .content {
        overflow: hidden;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
    }
    .section {
        background: transparent;
        position: relative; 
        overflow: hidden;
    }
}

@keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
}

.path {
    stroke-dasharray: 11000;
    stroke-dashoffset: 11000;
    animation: dash 12s ease-in forwards;
    animation-delay: 1s;
}
/* .path.wait {
    animation-delay: 3s;
} */