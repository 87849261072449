.modalBody {
    font-family: 'Playfair Display SC', serif;
    position: absolute;
    z-index: 5;
    right: 0;
    top: 100px;
    background-color: white;
    flex-direction: column;
    display: flexbox;
    width: 25%;
    padding: 10px;
    padding-top: 0;
    margin: 10px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
}

.modalRow {
    display: flex;
    flex-direction: row;
}
.modalColumn {
    display: flex;
    flex-direction: column;
}

.copyButton {
    font-family: 'Playfair Display SC', serif;
    color: white;
    display: flex;
    position: relative;
    border-color:black;
    background-color: black;
    border-width: 1px;
    border-radius: 5px;
    padding: 10px;
    width: 33%;
    cursor: pointer;
}

.containerSvg {
    display: none;
    margin: 0;
    height: 0;
}

@media only screen and (max-width: 505px) {
    .modalBody {
        width: 80%;
    }
    .copyButton {
        display: none;
    }
    .containerSvg {
        display: flex;
        margin-right: 10px; 
        height: 35px;
        align-self: center;
    }

}